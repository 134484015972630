.tg-block {
  display: grid;
  background: black;
  border-radius: 20px;
  overflow: hidden;
  grid-gap: 20px;

  &__image-wrapper{
    position: relative;
  }

  &__picture{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__image{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__title, &__description {
    color: white;
  }

  &__link-wrap{
    background: whitesmoke;
    width: 250px;
    height: 50px;
    border-radius: 30px;
  }

  &__link{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}

@include respond-up('s-large') {
  .tg-block {
    grid-template-columns: 1fr 1fr;

    &__image-wrapper{
      padding-bottom: 30vw;
    }

    &__text-wrapper{
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px;
    }

    &__title{
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    &__description{
      margin-bottom: 20px;
    }
  }
}

@include respond-up('medium') {
  .tg-block {

  }
}

@include respond('medium') {
  .tg-block {

  }
}

@include respond-down('medium') {
  .tg-block {
    grid-template-columns: 1fr;
    grid-template-rows: 300px 1fr;

    &__text-wrapper{
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px;
    }

    &__title{
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    &__description{
      margin-bottom: 20px;
    }
  }
}

@include respond-down('small') {
  .tg-block {

  }
}