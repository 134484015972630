.category-grid{
  display: grid;
  grid-gap: 20px;
}

@include respond-up('s-large'){
  .category-grid{
    margin-top: 50px;
    grid-template-areas: "one one one two two two three three three four four four"
                          "five five five five six six six six seven seven seven seven";
    &._subcategory{
      grid-template-columns: repeat(4, 1fr);
      grid-template-areas: unset;
    }
  }
}

@include respond-down('medium') {
  .category-grid{
    margin-top: 30px;
    grid-template-columns: repeat(2, 1fr);
  }
}