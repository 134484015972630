.proof {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--header-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: -1;
  pointer-events: none;
  opacity: 0;

  &._before-proof {
    z-index: 999999;
    opacity: 1;
    pointer-events: all;
    background: var(--white-color-60);
  }

  &__wrap{
    overflow-y: scroll;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 100px;
    box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
  }

  &__title {
    font-weight: 500;
    font-size: 35px;
    line-height: 47/35*1em;
    letter-spacing: -0.03em;
    color: var(--black-color);
    padding-bottom: 15px;
  }

  &__subtitle {
    font-size: 14px;
    line-height: 19/14*1em;
    letter-spacing: -0.03em;
    color: var(--gray-text-color);
    max-width: 440px;
    padding-bottom: 35px;
    text-align: center;
  }

  &__buttons-container {
    display: flex;
    margin: 20px 0;
  }

  &__link {
    width: 134px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--primary-color-border);
    color: var(--primary-color);
    border-radius: 100px;
    transition: {
      property: background-color, color;
      duration: .4s;
    };

    &:hover {
      background: var(--primary-color);
      color: #FFFFFF;
    }

    &:first-child {
      margin-right: 10px;
    }
  }

  &__small{
    font-size: 10px;
    max-width: 500px;
  }

  &__date{
    width: 175px;
    margin-right: 20px;
  }

  &__rules{
    font-size: 12px;
    border-bottom: 1px solid transparent;
    transition: border .3s;
    margin-top: 20px;
    &:hover{
      border-bottom: 1px solid black;
    }
  }
}

@include respond-down('medium') {
  .proof {
    height: 100vh;
    width: 100vw;
    &__wrap{
      height: 100%;
      padding: 50px 20px;
      width: 100vw;
      justify-content: unset;
    }

    &__link{
      background: var(--primary-color);
      color: #FFFFFF;
    }

    &__title {
      font-weight: 500;
      font-size: 25px;
      line-height: 47/35*1em;
      letter-spacing: -0.03em;
      color: var(--black-color);
      padding-bottom: 15px;
    }

    &__subtitle {
      font-size: 13px;
      line-height: 19/14*1em;
      letter-spacing: -0.03em;
      color: var(--gray-text-color);
      padding-bottom: 20px;
      text-align: center;
    }

    &__buttons-container{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      max-width: 500px;
    }

    &__date{
      margin-right: unset;
      margin-bottom: 10px;
    }

    &__rules{
      font-size: 12px;
      border-bottom: 1px solid transparent;
    }
  }
}

@include respond-down('small') {
  .proof{

  }
}