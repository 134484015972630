.breadcrumbs {
  color: grey;
  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    display: block;
  }

  &__item_delimiter {
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__link,
  &__item_delimiter-icon {
    display: flex;
  }
}

@include respond-up("s-large") {
  .breadcrumbs {
    margin-top: 35px;
    margin-bottom: 20px;

    &__list {
      margin: -4px;
    }

    &__item {
      padding: 4px;
    }

    &__link {
      transition: color .4s;
      padding: 4px;
      margin: -4px;

      &:hover {
      }
    }
  }
}

@include respond("medium") {
  .breadcrumbs {
    margin-top: 33px;
  }
}

@include respond-down("medium") {
  .breadcrumbs {
    margin-bottom: 15px;
    &__list {
      margin: -3px;
    }

    &__item {
      padding: 3px;

      &:last-child {
        white-space: nowrap;
        //overflow: hidden;
        //text-overflow: ellipsis;
      }
    }

    &__link {
      padding: 3px;
      margin: -3px;
    }
  }
}

@include respond-down("small") {
  .breadcrumbs {
    font-size: 14px;
    margin-top: 10px;
    &__list{
      overflow: scroll;
      &::-webkit-scrollbar{
        display: none;
      }
    }
    &__item{
      white-space: nowrap;
      &_delimiter{
        margin: 0 5px;
      }
    }
  }
}
