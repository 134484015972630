.cart-page {
  &__title {
    font-weight: 600;
  }

  &__products {
    background-color: white;
  }

  &__list {
    background-color: var(--background-color);
    border-bottom: 1px solid var(--light-gray-border);
  }

  &__totals {

  }

  &__total {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--grey-color);
    width: 100%;
  }

  &__total-sum {
    display: flex;
  }

  &__total-text {
    font-style: normal;
    font-weight: normal;
    line-height: 135%;
    letter-spacing: -0.03em;
    color: var(--gray-text-color);
  }

  &__total-price {
    display: flex;
    align-items: flex-end;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 135%;
    text-align: right;
    letter-spacing: -0.03em;
  }

  &__total-price-value {
    margin-left: 3px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 135%;
    text-align: right;
    letter-spacing: -0.03em;
  }

  &__total-value {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__total-free-wrap {
    display: flex;
    align-items: center;
  }

  &__totals-button-link {
    width: 100%;
  }

  &__payment-variants {
    display: flex;
  }

  &__payment-variants-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__payment-variants-picture {
    width: 44px;
    height: 22px;
    display: block;
    margin-right: 8px;
  }

  &__payment-cash {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 135%;
    letter-spacing: -0.03em;
    color: var(--black-color);
    padding-top: 6px;
  }

  &__empty-wrap {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 45px 20px;
  }

  &__empty-text {
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    letter-spacing: -0.03em;
    color: var(--black-color);
    margin-bottom: 20px;
  }

  &__totals{
    display: flex;
    align-items: baseline;
    padding-top: 10px;
  }

  &__totals-sum{
    font-size: 25px;
    font-weight: 600;
    margin-left: 20px;
  }

  &__empty-button{
    padding: 10px 20px;
    background: black;
    color: white;
  }


}

@include respond-up('s-large') {
  .cart-page {
    padding-top: 75px;
    padding-bottom: 140px;

    &__bottom {
      margin-top: 80px;
    }

    &__totals {
      display: flex;
      justify-content: flex-end;
      margin-right: 66px;
    }

    &__empty-text {
      font-size: 28px;
    }

    &__form{
      max-width: 720px;
    }
  }
}

@include respond('medium') {
  .cart-page {
    padding-top: 75px;
    padding-bottom: 90px;

    &__total-free-wrap {
      padding-bottom: 33px;
    }

    &__total-text {
      font-size: 15px;
    }

    &__total-value {
      padding-bottom: 20px;
      margin-bottom: 22px;
      border-bottom: 1px solid var(--light-gray-border);
    }

    &__totals{
      justify-content: flex-end;
      margin-right: 38px;
    }

    &__empty-wrap {
      padding: 45px 0;
    }

    &__empty-text {
      font-size: 20px;
    }
  }
}

@include respond-down('small') {
  .cart-page {
    padding-top: 55px;
    padding-bottom: 40px;

    &__totals {
      justify-content: space-between;
    }

    &__totals-sticky {
      display: none;
    }

    &__totals-button-link {
      width: 100%;
      padding-top: 12px;
      padding-bottom: 12px;
    }

    &__totals-button {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid var(--light-gray-border);
      width: 100%;
    }

    &__total {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid var(--grey-color);

      &_second {
        align-items: flex-start;
      }
    }

    &__total-free-wrap {
      padding-bottom: 12px;
    }

    &__total-value {
      width: 100%;
      padding-bottom: 24px;
      display: flex;
      justify-content: space-between;
    }

    &__total-label {
      display: none
    }

    &__total-text {
      font-size: 14px;
    }

    &__offer-title {
      font-size: 23px;
    }

    &__empty-text {
      font-size: 18px;
    }
  }
}