.product{
  &__image-block{
    position: relative;
  }

  &__picture{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__image{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__recall{
    width: 300px;
    height: 50px;
    border-radius: 30px;
    background-color: black;
    color: white;
    font-weight: bold;
    font-size: 20px;
  }

  &__recall-link{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__parameter-name{
    font-size: 16px;
    font-weight: 600;
  }

  &__available{
    margin: 20px 0;
    font-size: 16px;
    font-weight: 600;
  }

  &__description{
    border-top: 1px solid lightgray;
    padding-top: 20px;
  }

  &__parameter-name{
    margin-bottom: 10px;
    display: block;
  }

  &__value{
    display: flex;
    align-items: baseline;
    height: 30px;
    label{
      font-size: 15px;
      color: grey;
      height: unset;
      padding: unset;
      margin-left: 10px;
    }
  }

  &__not-avail-text{
    margin-top: 15px;
  }

  &__recall{
    margin-top: 15px;
  }
}

@include respond-up('s-large'){
  .product{
    &__top{
      padding: 30px 0;
      display: grid;
      grid-gap: 40px;
      grid-template-columns: 60% 1fr;
    }

    &__image-block{
      height: 43vw;
      max-height: 400px;
    }

    &__info-block{
      margin-top: 30px;
    }

    &__price{
      font-size: 40px;
      line-height: 34px;
      font-weight: 700;
    }

    &__parameters{
      display: flex;
      flex-direction: column;
      margin-top: 20px;
    }

    &__parameter{
      margin-top: 20px;
    }

    &__value{
      margin-bottom: 5px;
    }

    &__socials{
      margin-top: 40px;
    }
  }
}

@include respond-down('medium'){
  .product{
    &__top{
      padding: 25px 0;
      display: grid;
      grid-gap: 40px;
      grid-template-columns: 1fr 1fr;
    }

    &__info-block{
      margin-top: 20px;
    }

    &__price{
      font-size: 30px;
      line-height: 34px;
      font-weight: 700;
    }

    &__parameter{
      margin-top: 20px;
    }

    &__socials{
      margin-top: 20px;
    }
  }
}

@include respond-down('small'){
  .product{
    &__top{
      grid-template-columns: 1fr;
    }

    &__image-block{
      height: 88vw;
      max-height: 500px;
    }

    &__value{
      margin-bottom: 10px;
    }
  }
}

