.hero{
  &__image-wrap{
    height: 100vh;
    position: relative;
  }

  &__picture{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__image{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text-wrap{
    position: absolute;
  }

  &__title {
    color: #fff;
  }

  &__description{
    color: #fff;
  }
}

@include respond-up('s-large'){
  .hero{
    margin-top: -100px;

    &__text-wrap{
      top: 43%;
      left: var(--grid-spacer-and-indent);
      right: var(--grid-spacer-and-indent);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__title {
      font-size: calc(53/1600 * 100vw);
    }

    &__description{
      margin-top: 50px;
      font-size: calc(23/1600 * 100vw);
      max-width: calc(800/1600 * 100vw);
    }
  }
}
@include respond('medium') {
  .hero{
    margin-top: -55px;
    &__text-wrap{
      top: 43%;
      left: var(--grid-spacer-and-indent);
      right: var(--grid-spacer-and-indent);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__title {
      font-size: calc(40/768 * 100vw);
    }

    &__description{
      margin-top: 50px;
      font-size: calc(15/768 * 100vw);
      max-width: calc(400/768 * 100vw);
    }
  }
}

@include respond-down('small') {
  .hero{
    &__text-wrap{
      top: 50%;
      left: var(--grid-spacer-and-indent);
    }

    &__title {
      font-size: calc(35/768 * 100vw);
    }

    &__description{
      margin-top: 50px;
      font-size: calc(15/368 * 100vw);
      max-width: calc(300/368 * 100vw);
    }
  }
}