.catalog-grid{
  display: grid;
  border-top: 1px solid lightgray;
  border-left: 1px solid lightgray;
}

@include respond-up('s-large') {
  .catalog-grid{
    margin-top: 30px;
    grid-template-columns: repeat(4, 1fr);
  }
}

@include respond('medium'){
  .catalog-grid{
    margin-top: 30px;
    grid-template-columns: repeat(3, 1fr);
  }
}

@include respond-down('small'){
  .catalog-grid{
    margin-top: 20px;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 450px) {
  .catalog-grid{
    grid-template-columns: 1fr;
  }
}