.pagination{
  justify-self: center;
  margin-top: 20px;
  &__list{
    display: flex;
    align-items: baseline;
    justify-content: center;
  }

  &__item{
    color: grey;
    font-size: 20px;
    margin-right: 5px;
    &_previous{
      margin-right: 10px;
    }
    &_next{
      margin-left: 10px;
    }
    &_active{
      color: black;
      font-weight: bold;
      font-size: 25px;
    }
  }
}