.simple-search {
  padding-top: 20px;
  &__item{
    display: grid;
    grid-template-columns: 50px 1fr;
    padding-bottom: 3px;
    align-items: center;
  }

  &__item-image-wrap{
    position: relative;
    height: 50px;
    width: 50px;
  }

  &__picture{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__image{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__item-name{
    margin-left: 10px;
    font-size: 13px;
  }

  &__show-all-button-wrapper{
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }

  &__total{
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    font-size: 15px;
  }
}

@include respond-up('large') {
  .simple-search {
    &__item {
      //&:hover {
      //  .simple-search{
      //    &__item-name {
      //      border-bottom: 1px solid black;
      //    }
      //  }
      //}
    }

    &__item-name{
      //border-bottom: 1px solid transparent;
      //transition: border 0.3s;
    }
  }
}

@include respond-up('medium') {
  .simple-search {

  }
}

@include respond('medium') {
  .simple-search {

  }
}

@include respond-down('medium') {
  .simple-search {

  }
}

@include respond-down('small') {
  .simple-search {

  }
}