.offices-list {
  &__item {
    //&:not(:first-child) {
      border-top: 1px solid gray;
    //}
  }

  &__content {
    height: 0;
    transition: height .5s;
    overflow: hidden;
  }

  &__link {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  &__link-title {
    color: gray;
  }

  &__link-subtitle {
    margin-top: 4px;
  }

  &__picture {
    display: flex;
    overflow: hidden;
    border-radius: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__picture-link {
    position: absolute;
  }

  &__icon {
    display: flex;
    height: max-content;

    &_picture {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      z-index: 20;
    }
  }

  &__item-top {
    display: flex;
    position: relative;
  }

  &__opening-wrap,
  &__services-wrap {
    display: flex;
    margin-top: 14px;
  }

  &__schedule{
    display: block;
    margin-top: 5px;
  }

  &__opening-list,
  &__services {
    margin-left: 14px;
  }

  &__description {
    margin-top: 14px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -0.02em;
    color: gray;
  }

  &__route-link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    color: white;
    background-color: black;
    width: 180px;
    height: 40px;
    border-radius: 30px;
  }

  &__phone {
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
    letter-spacing: -0.02em;
    color: black;
    display: flex;
  }

  &__email {
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: black;
    display: flex;
  }

  &__opening-item,
  &__services {
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: gray;
  }
}

@include respond-up('s-large') {
  .offices-list {
    &__picture-link {
      top: 20px;
      left: 22px;
    }

    &__item{
      padding: 30px;
      transition: background-color .3s;
      background-color: transparent;
      &:hover{
        background-color: rgba(218, 218, 218, .2);
      }
    }

    &__link-subtitle{
      font-size: 18px;
      font-weight: 500;
    }

    &__picture {
      width: 74px;
      height: 74px;
    }

    &__content-inner {
      padding: 20px 0;
    }

    &__route-link {

    }

    &__phone,
    &__email {
      padding: 4px 0;
    }
  }
}

@include respond-up('medium') {
  .offices-list {
    &__description {
      line-height: 120%;
    }

    &__phone {
      font-size: 18px;
    }

    &__email {
      font-size: 15px;
    }

    &__opening-item,
    &__services {
      font-size: 14px;
    }
  }
}

@include respond('medium') {
  .offices-list {

  }
}

@include respond-down('medium') {
  .offices-list {

    &__item{
      padding: 30px;
    }

    &__picture-link {
      top: 18px;
      left: 18px;
    }

    &__picture {
      width: 60px;
      height: 60px;
    }

    &__link-subtitle{
      font-size: 16px;
      font-weight: 500;
    }

    &__content-inner {
      padding: 20px 0;
    }

    &__route-link {
      margin-top: 15px;
    }

    &__phone,
    &__email {
      padding: 3px 0;
    }
  }
}

@include respond-down('small') {
  .offices-list {
    &__description {
      line-height: 125%;
    }

    &__phone {
      font-size: 16px;
    }

    &__email {
      font-size: 14px;
    }

    &__opening-item,
    &__services {
      font-size: 13px;
    }
  }
}