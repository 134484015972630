.cart-success-page {
  &__done {
    background-color: white;
  }

  &__head {
    display: flex;
    align-items: center;
  }

  &__subtitle.text {
    margin-top: 20px;
    color: var(--grey-text-color);
  }

  &__success-wrap {
    background-color: var(--gray-background-color);
    padding: 5px 5px 5px 28px;
    border-radius: 0 100px 100px 0;
  }

  &__success-icon {
    background-color: var(--primary-color);
    width: 44px;
    height: 44px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__buttons-block {
    background-color: white;
  }

  &__buttons-wrap {
    display: flex;
  }

  &__button{
    padding: 10px 20px;
    background: black;
    color: white;
  }
}

@include respond-up('large') {
  .cart-success-page {
    padding-bottom: 120px;

    &__done {
      padding: 20px 0 50px;
    }

    &__subtitle {
      padding: 0 38px;
    }

    &__buttons-block {
      padding: 50px 38px;
    }
  }
}

@include respond-up('medium') {
  .cart-success-page {
    &__title {
      padding-left: 25px;
    }

    &__buttons-block {
      margin-top: 14px;
      padding: 35px 28px;
    }

    &__buttons-wrap {
      margin-top: 24px;
    }

    &__button {
      &:nth-child(n + 2) {
        margin-left: 20px;
      }
    }
  }
}

@include respond('medium') {
  .cart-success-page {
    padding-bottom: 90px;

    &__done {
      padding: 20px 0 35px;
    }

    &__subtitle {
      padding: 0 28px;
    }
  }
}

@include respond-down('medium') {
  .cart-success-page {

  }
}

@include respond-down('small') {
  .cart-success-page {
    padding-bottom: 50px;

    &__done {
      padding: 20px 0 30px;
    }

    &__head {
      padding-right: 18px;
    }

    &__title {
      padding-left: 17px;
    }

    &__subtitle {
      padding: 0 18px;
    }

    &__buttons-block {
      margin-top: 8px;
      padding: 30px 18px;
    }

    &__buttons-wrap {
      margin-top: 20px;
      flex-direction: column;
    }

    &__button.button {
      width: 100%;

      &:nth-child(n + 2) {
        margin-top: 10px;
      }
    }
  }
}