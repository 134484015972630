.card-item{
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
  &._no-blur{
    .card-item{
      &__image-wrap{
        filter: unset;
      }
    }
  }
  &__link{
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__image-wrap{
    position: relative;
    filter: blur(5px);
    transition: filter .3s;
  }

  &__picture{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__image{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__info{
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    flex: 100%;
  }

  &__name{
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    &_not-available{
      margin-top: 30px;
    }
  }

  &__subtitle{
    margin-top: 10px;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: gray;
  }

  &__price{
    font-weight: 700;
    font-size: 20px;
    line-height: 18px;
    text-align: center;
    margin-top: 10px;
  }

  &__to-cart{
    margin-top: 20px;
    padding: 10px 0 12px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__to-cart-text{
    margin-left: 10px;
    margin-top: 5px;
  }
}

@include respond-up('s-large') {
  .card-item{
    transition: box-shadow .3s;
    &:hover {
      box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2);
    }

    &__link{
      padding: 20px;
    }

    &__image-wrap{
      padding-bottom: calc(300/1600 * 100vw);
    }

    &__to-cart{
      border: 1px solid transparent;
      transition: border .3s;
      border-radius: 30px;
      &:hover{
        border: 1px solid black;
      }
    }
  }
}

@include respond('medium'){
  .card-item{
    &__link{
      padding: 20px;
    }

    &__image-wrap{
      padding-bottom: 21vw;
    }
  }
}

@include respond-down('small'){
  .card-item{
    &__link{
      padding: 15px;
    }

    &__image-wrap{
      padding-bottom: 21vw;
    }
  }
}

@media (max-width: 450px) {
  .card-item{
    &__image-wrap{
      padding-bottom: 46vw;
    }
  }
}
