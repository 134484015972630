.content-header{
  &__title{
    display: block;
  }
}

@include respond-up('s-large') {
  .content-header{
    &__title{
      font-size: 30px;
      margin-bottom: 30px;
    }
  }
}

@include respond-down('medium') {
  .content-header{
    &__title{
      font-size: 25px;
      margin-bottom: 25px;
    }
  }
}