.category-item{
  &__link{
    display: block;
    position: relative;
    &:hover{
      .category-item{
        &__image, &__subcategory-image{
          transform: scale(1.1);
        }
      }
    }
  }

  &__picture{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }

  &__image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform .3s;
  }

  &__subcategory-image{
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform .3s;
  }

  &__name{
    position: absolute;
    top: 0;
    color: white;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
  }
}

@include respond-up('s-large'){
  .category-item{
    &:first-child{
      grid-area: one;
    }

    &:nth-child(2) {
      grid-area: two;
    }

    &:nth-child(3) {
      grid-area: three;
    }

    &:nth-child(4) {
      grid-area: four;
    }

    &:nth-child(5) {
      grid-area: five;
    }

    &:nth-child(6) {
      grid-area: six;
    }

    &:last-child{
      grid-area: seven;
    }

    &._subcategory{
      grid-area: unset;
    }
    &__link{
      padding-bottom: calc(201 / 1600 * 100vw);
    }

    &__name{
      font-size: calc(25 / 1600 * 100vw);
      font-weight: 1000;
      padding: 20px;
    }
  }
}

@include respond-down('medium') {
  .category-item{
    &:first-child{
    }

    &__link{
      padding-bottom: calc(198 / 768 * 100vw);
    }

    &__name{
      font-size: calc(28 / 768 * 100vw);
      font-weight: 1000;
      padding: 20px;
    }
  }
}