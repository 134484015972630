.header {
  position: sticky;
  width: 100%;
  z-index: 4700;
  background: transparent;
  transition: background-color .3s;
  top: 0;

  &__content{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &._sticky{
    background: #fff;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.2);
  }

  //&__wrap {
  //  height: 100px;
  //  position: relative;
  //  display: flex;
  //  justify-content: space-between;
  //  align-items: center;
  //}

  &__logo {
    svg{
      height: 90px;
      width: 145px;
    }
  }

  &__menu-link{
    padding-bottom: 3px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    color: #000;
    border-bottom: 2px solid transparent;
    transition: border .4s;

    &:first-child{
      margin-right: 30px;
    }

    &:hover{
      border-bottom: 2px solid black;
    }
  }

  &__phone{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    margin-right: 30px;
  }

  &__modal{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #000;
  }

  &__humburger {
    position: relative;
  }

  &__right{
    display: flex;
    align-items: center;
  }

  &__cart{
    position: relative;
  }

  &__cart-quantity{
    position: absolute;
    top: 8px;
    left: 8px;
    right: 0;
    bottom: 0;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    letter-spacing: -0.03em;
    background: black;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 17px;
    height: 17px;
    border-radius: 100%;
    font-size: 12px;
    line-height: 110%;
  }

  &__search-wrap {
    position: relative;
  }

  &__search-bar{
    position: absolute;
  }

  &__search-link{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__search-bar{
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s;
    &._open{
      opacity: 1;
      pointer-events: all;
    }
  }
}

@include respond-up('s-large') {
  .header{
    &__menu{
      margin-left: calc(80/1600 * 100vw);
      display: flex;
    }

    &__hamburger-wrap{
      display: none;
    }

    &__phones{
      margin-top: 25px;
      &._flex{
        display: flex;
        margin-top: 0;
      }
      &._non-index-flex{
        display: flex;
        margin-top: 0;
      }
    }

    &__content{
      height: 100px;
    }

    &__left{
      display: flex;
      align-items: center;
    }

    &__drop-menu{
      display: none;
    }

    &__recall-link-icon{
      display: none;
    }

    &__cart{
      margin-left: 20px;
    }

    &__search-wrap{
      margin-left: 15px;
    }

    &__search-bar{
      top: 35px;
      width: 300px;
      right: -45px;
    }
  }
}

@include respond-down('medium') {
  .header{
    transition: background-color .6s;
    &._opened{
      background-color: white;
      .header{
        &__drop-menu{
          opacity: 1;
          pointer-events: all;
          z-index: 9000;
        }
      }
    }

    &__phones{
      display: none;
    }

    &__drop-menu{
      position: absolute;
      top: 55px;
      left: 0;
      right: 0;
      opacity: 0;
      pointer-events: none;
      transition: opacity .6s;
      background: white;
    }

    &__content{
      height: 55px;
    }

    &__logo{
      display: none;
    }

    &__menu{
      display: none;
    }

    &__recall-link{
      display: none;
    }

    &__recall-link-icon{
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__recall-link-title{
      display: none;
    }

    &__cart{
      margin-left: 20px;
    }

    &__search-bar{
      top: 35px;
      width: 300px;
      right: -45px;
    }
  }
}