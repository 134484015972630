.index-page{
  &__categories{
    display: grid;
    grid-gap: 20px;
  }
}

@include respond-up('s-large'){
  .index-page{
    &__categories{
      margin-top: 50px;
      grid-template-columns: repeat(4, 1fr);
    }

    &__tg-block{
      margin-top: 50px;
    }

    &__popular-goods{
      margin-top: 50px;
    }
  }
}

@include respond-down('medium') {
  .index-page{
    &__categories{
      margin-top: 30px;
      grid-template-columns: repeat(2, 1fr);
    }

    &__tg-block{
      margin-top: 30px;
    }

    &__popular-goods{
      margin-top: 30px;
    }
  }
}