.cart-item {
  padding: 20px 0;
  align-items: center;
  border-top: 1px solid grey;

  &__image-container{
    position: relative;
    display: block;
    background: #fff;
  }

  &__image-link{
    position: relative;
    padding-bottom: 120px;
  }

  &__picture{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__image{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__name {
    font-style: normal;
    font-weight: normal;
    line-height: 135%;
    letter-spacing: -0.03em;
    color: black;
  }

  &__price {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    letter-spacing: -0.03em;
    color: black;
  }

  &__counter{
    display: flex;
  }

  &__up-down-button{
    border: unset;
    background: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
      pointer-events: none;
    }
  }

  &__up-down-input{
    padding: 0 10px;
  }

  &__down-button{
    padding-right: 10px;
    border-right: 1px solid lightslategrey;
  }

  &__up-button{
    padding-left: 10px;
    border-left: 1px solid lightslategrey;
  }

  &__remove{
    width: 34px;
    height: 34px;
    background-color: #fff;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__price{
    font-size: 25px;
    font-weight: 600;
  }

  &__parameters{
    font-size: 12px;
    color: grey;
  }
}

@include respond-up('s-large') {
  .cart-item {
    display: grid;
    grid-template-columns: 140px 287px auto 120px 34px;

    &__image-container{
      width: 92px;
      height: 115px;
    }

    &__name-wrap{
      margin-left: 20px;
    }

    &__name {
      font-size: 15px;
      max-width: 287px;
      margin-right: 29px;
    }

    &__parameters{
      margin-top: 10px;
    }

    &__counter {
      border-bottom: 1px solid lightslategrey;
      max-width: fit-content;
      justify-self: center;
      padding-bottom: 10px;
      margin-top: 10px;
    }

    &__price-container {
      flex-direction: column;
      margin-right: 21px;
    }
  }
}

@include respond-up('medium') {
  .cart-item{
    &__parameters{
      &_mobile{
        display: none;
      }
    }
  }
}

@include respond('medium') {
  .cart-item {
    display: grid;
    grid-template-columns: 92px 1fr 1fr 70px 34px;
    grid-gap: 10px;

    &__name {
      max-width: calc(220 / 768 * 100vw);
      margin-right: 26px;
    }

    &__parameters{
      margin-top: 10px;
    }

    &__vendor{
      font-size: 14px;
      padding-top: 12px;
    }

    &__counter {
      margin-right: 21px;
    }

    &__size{
      grid-area: size;
      margin-top: 18px;
      text-align: right;
    }

    &__price-container {
      flex-direction: column;
      margin-right: 18px;
    }

    &__image-container{
      width: 92px;
      height: 115px;
    }

    &__name {
      font-size: 14px;
    }

    &__vendor{
      font-size: 14px;
      padding-top: 10px;
    }
  }
}

@include respond-down('small') {
  .cart-item {
    display: grid;
    grid-template-areas:
            "picture price price"
            "picture name name"
            "picture counter counter"
            "params params params";
    justify-content: normal;
    position: relative;

    &__parameters{
      display: none;
      &_mobile{
        grid-area: params;
        font-size: 12px;
        color: grey;
      }
    }

    &__image-link{
      margin-right: 14px;
      grid-area: picture;
      width: 105px;
      height: 131px;
    }

    &__image-container{
      width: 105px;
      height: 131px;
    }

    &__name-wrap{
      grid-area: name;
    }

    &__name {
      font-size: 13px;
      margin-bottom: 15px;
      max-width: 219px;
    }

    &__vendor{
      font-size: 12px;
      padding-top: 6px;
    }

    &__counter {
      grid-area: counter;
      margin-right: 54px;
      margin-bottom: 10px;
    }

    &__size{
      grid-area: size;
      margin-top: 18px;
    }

    &__color{
      grid-area: color;
    }

    &__price-container {
      grid-area: price;
      margin-left: 0;
      margin-bottom: 12px;
    }

    &__price {
      text-align: left;
      &_old{
        margin-left: 12px;
      }
    }

    &__remove {
      width: 26px;
      height: 26px;
      position: absolute;
      top: 16px;
      right: 15px;
    }
  }
}