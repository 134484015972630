:root {
  --default-input-height: 37px;
  --errors-color: var(--alert-color, #b50000)
}

$inputs: 'input[type="text"], input[type="search"], input[type="password"]';

form {

  #{$inputs}, textarea, select {
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px white inset;
      -webkit-text-fill-color: black;
      transition: color .4s, text-fill-color .4s, box-shadow .4s !important;
      transition-timing-function: var(--default-transition-function);
    }
  }

  label {
    color: black;
    margin-bottom: 2px;
    display: block;
    font-weight: 400;
    line-height: 110%;
    font-stretch: 110%;
    font-variation-settings: var(--font-settings);
  }

  ul.errors {
    margin-top: 10px;
    list-style: none;
    color: var(--errors-color);
    font-size: 13px;

    li {
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form-field {
    margin-bottom: 10px;
  }

  #{$inputs}, textarea, select {
    border: 0;
    border-bottom: 1px solid black;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    font-size: 14px;
    width: 100%;
    display: block;
    appearance: none;
    font-family: var(--font);
  }

  #{$inputs}, select {
    height: var(--default-input-height);
  }

  textarea {
    padding: 5px 10px;
  }

  //.button, button {
  //  padding: 0;
  //  margin: 0;
  //  text-align: center;
  //  transition: var(--default-transition);
  //  border: none;
  //  background: black;
  //  font-size: 16px;
  //  cursor: pointer;
  //  text-decoration: none;
  //  border-radius: 100px;
  //  color: #fff;
  //  font-weight: 500;
  //  line-height: 110%;
  //  text-transform: uppercase;
  //  font-stretch: 151%;
  //  font-variation-settings: var(--font-settings);
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //}
}

@include respond-up('s-large') {
  form {
    #{$inputs}, textarea, select, label {
      font-size: 30px;
      padding-bottom: 20px;
      height: 57px;
    }
  }
}

@include respond('medium') {
  form {
    #{$inputs}, textarea, select, label {
      font-size: 25px;
      padding-bottom: 16px;
    }
  }
}

@include respond-down('small') {
  form {
    #{$inputs}, textarea, select, label {
      font-size: 20px;
      padding-bottom: 12px;
    }
  }
}