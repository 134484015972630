.catalog{
  &__sort{
    display: flex;
    align-items: baseline;
    margin-top: 20px;
  }

  &__sort-wrap{
    margin-left: 10px;
  }
}

@include respond-up('s-large') {
  .catalog{
  }
}