.footer {
  background: black;
  &__contact-title, &__copy, &__policy{
    @extend .text__mini;
    color: var(--white-color-60) !important;
  }

  &__contact-link{
    color: #fff;
  }
  &__bottom-left-wrap {
    display: flex;
  }

  &__form-policy-wrap {
    color: var(--primary-color-opacity-40);
  }

  &__form-policy-link {
    border-bottom: 1px solid var(--primary-color-opacity-40);
  }

  &__input {
    background: var(--background-color);
  }

  &__wrap {
    grid-row-gap: 0;
  }

  &__rclass {
    color: var(--primary-color-opacity-40);
  }

  &__rclass-link {
    color: black;
    margin-left: 4px;
  }

  &__bottom-wrap {
    display: flex;
    justify-content: space-between;
  }

  &__contact-link{
    display: block;
  }
}

@include respond-up('s-large') {
  .footer {
    margin-top: 90px;
    padding-top: 60px;
    padding-bottom: 90px;

    &__logo{
      grid-column: 1 / 3;
    }

    &__contacts{
      display: flex;
      flex-direction: column;
      grid-column: 3 / 6;
    }

    &__info-block{
      grid-column: 10 / 13;
    }

    &__bottom-wrap {
      grid-column: 1/8;
    }

    &__policy {
    }

    &__left-wrap {
      grid-column: 1/5;
      border-right: 1px solid black;
      padding-top: 120px;
      padding-bottom: 40px;
    }

    &__title {
      max-width: var(--grid-column3);
    }

    &__address-wrap {
      display: flex;
      align-items: center;
      margin-top: 240px;
    }

    &__prime-logo {
      transform: translateY(10px);
      margin-left: 70px;
    }

    &__address {
      transform: translateY(5px);
      padding-left: 30px;
      margin-left: 30px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        background: black;
        height: 35px;
        width: 1px;
        left: 0;
        top: 0;
      }
    }

    &__right-wrap {
      grid-column: 5/8;
      padding-top: 120px;
      padding-bottom: 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__email {
      width: var(--grid-column);
      white-space: nowrap;
    }

    &__phone {
      display: flex;
    }

    &__bottom-wrap {
      border-top: 1px solid black;
      padding-top: 46px;
    }

    &__copy {
      padding: 14px 0;
      min-width: var(--grid-column1);
    }

    &__policy {
      margin-left: 40px;
      padding: 14px 0;
    }

    &__social-large {
      grid-column: 5/6;
    }

    &__field {
      margin-top: 0;

      &:not(:first-child) {
        margin-top: 55px;
      }
    }

    &__address-mob {
      display: none;
    }

    &__form-bottom {
      justify-content: space-between;
    }

    &__info-block{
      display: flex;
      flex-direction: column;
      align-content: flex-end;
      flex-wrap: wrap;
    }

    &__policy, &__copy{
      text-align: right;
    }
  }
}

@include respond-up('medium') {
  .footer {
    &__logo {
      svg{
        height: 90px;
        width: 145px;
      }
    }
  }
}

@include respond('medium') {
  .footer {
    padding: 50px 0;
    &__logo{
      grid-column: 1 / 3;
    }

    &__contacts{
      grid-column: 3 / 5;
    }

    &__info-block{
      grid-column: 5 / 7;
    }

    &__copy{
      margin-bottom: 30px;
    }
  }
}

@include respond-down('medium') {
  .footer {
    margin-top: 30px;
  }
}

@include respond-down('small') {
  .footer {
    padding: 40px 0;
    &__logo {
      grid-row: 1 / 2;
      svg{
        height: 70px;
        width: 100px;
      }
    }

    &__contacts{
      margin-left: -30px;
      grid-column: 3 / 5;
      grid-row: 1 / 2;
    }

    &__info-block{
      grid-row: 3 / 4;
      grid-column: 1 / 5;
    }
  }
}