.socials {
  &_dark{
    .socials{
      &__item{
        svg{
          path{
            fill: var(--black-color);
          }
        }
      }

      &__link{
        border: 1px solid var(--grey-stroke-color);
        &:hover{
          border: 1px solid var(--black-color);
        }
      }
    }
  }

  &_light{
    .socials{
      &__link{
        border: 1px solid var(--white-color-40);
        &:hover{
          border: 1px solid var(--white-color);
        }
      }
    }
  }

  &__items{
    display: flex;
    align-items: center;
  }

  &__item{
    margin-right: 6px;
    &:last-child{
      margin: 0;
    }
    svg{
      width: 35px;
      height: 35px;
    }
  }

  &__link{
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    transition: border var(--default-transition-timing) var(--default-transition-function);
  }
}

@include respond-up('s-large') {
  .socials {
    &__button{
      font-size: 20px;
      padding: 15px 30px;
    }

    &__item{
      &_viber{
        display: none;
      }
    }
  }
}

@include respond-up('medium') {
  .socials {

  }
}

@include respond('medium') {
  .socials {

  }
}

@include respond-down('medium') {
  .socials {

  }
}

@include respond-down('small') {
  .socials {

  }
}