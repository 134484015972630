.hamburger {
  display: flex;
  align-items: center;
  height: 100%;

  &__icon {
    display: flex;
    position: relative;
  }

  &__line {
    width: 100%;
    height: 2px;
    background-color: #000000;
    position: absolute;
    left: 0;
    transition: opacity, transform, background-color, width;
    transition-duration: var(--default-timing);
    transition-timing-function: var(--default-bezier);

    &_top {
      top: 0;
    }

    &_middle {
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }

    &_bottom {
      bottom: 0;
    }
  }

  &__title {
    margin-left: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    color: #000000;
    transition: color var(--default-timing) var(--default-bezier);
  }

  &._opened {
    .hamburger {
      &__line {
        &_top {
          transform: rotate(45deg) translate3d(5px, 5px, 0);
        }

        &_middle {
          opacity: 0;
        }

        &_bottom {
          transform: rotate(-45deg) translate3d(5px, -5px, 0);
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .hamburger {
    padding: 0 30px;
    margin-left: -30px;
  }
}

@include respond-up('medium') {
  .hamburger {
    &__icon {
      width: 28px;
      height: 16px;
    }
  }
}

@include respond('medium') {
  .hamburger {
    padding: 0 20px;
    margin-left: -20px;
  }
}

@include respond-down('medium') {
  .hamburger {
    &__title {
      display: none;
    }
  }
}

@include respond-down('small') {
  .hamburger {
    padding: 0 14px;
    margin-left: -14px;

    &__icon {
      width: 24px;
      height: 14px;
    }

    &._opened {
      .hamburger {
        &__line {
          &_top {
            transform: rotate(45deg) translate3d(4px, 4px, 0);
          }

          &_middle {
            opacity: 0;
          }

          &_bottom {
            transform: rotate(-45deg) translate3d(4px, -4px, 0);
          }
        }
      }
    }
  }
}