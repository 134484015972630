body {
  font-size: var(--body-font-size);
  font-family: var(--font);
  line-height: var(--body-line-height);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &._proof{
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
}

input, button, form {
  font-family: var(--font);
}