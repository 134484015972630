.cart-success {
  background-color: #ffffff;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.2);
  z-index: 9000;

  visibility: hidden;
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.4s;

  &._show {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }

  &__block {

  }

  &__inner {

  }

  &__data {

  }

  &__caption {
    font-size: 22px;
    font-weight: 500;
    color: var(--text-color);
  }

  &__order {
    background: black;
    color: white;
    padding: 10px 20px;
  }

  &__close {
    width: 24px;
    height: 24px;
  }
}

@include respond-up('medium') {
  .cart-success {
    padding: 20px 0;

    &__close {
      display: none;
    }

    &__data {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 0;
    }
  }
}


@include respond-down('small') {
  .cart-success {
    height: 139px;
    &__inner {
      padding: 16px 0;
      position: relative;
    }

    &__close {
      position: absolute;
      right: 0;
      top: 16px;
    }

    &__caption {
      margin-right: 24px;
      font-size: 18px;
    }

    &__order {
      margin-top: 16px;
      width: 100%;
      padding: 13px 20px;
    }
  }
}