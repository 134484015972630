.search-bar {
  background: white;
  filter: drop-shadow(0px 1px 15px rgba(0, 0, 0, 0.07));
  padding: 15px;
  &__form{
    input{
      font-size: 15px !important;
      height: 32px !important;
    }
    input::placeholder {
      font-size: 15px;
    }
  }
}

@include respond-up('s-large') {
  .search-bar {

  }
}

@include respond-up('medium') {
  .search-bar {

  }
}

@include respond('medium') {
  .search-bar {

  }
}

@include respond-down('medium') {
  .search-bar {
    padding: 10px;
  }
}

@include respond-down('small') {
  .search-bar {

  }
}