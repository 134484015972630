.index-addresses{
  color: #FFFFFF;
}

@include respond-up('s-large') {
  .index-addresses{
    &__item{
      margin-bottom: 10px;
    }

    &__name{
      margin-bottom: 4px;
    }
  }
}

@include respond('medium') {
  .index-addresses{
    &__item{
      margin-bottom: 10px;
    }

    &__name{
      margin-bottom: 4px;
    }
  }
}

@include respond-down('small') {
  .index-addresses{
    margin-top: 10px;
    font-size: 10px;

    &__list{
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    &__item{
      margin-bottom: 12px;
    }

    &__name{
      margin-bottom: 4px;
    }
  }
}