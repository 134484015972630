.offices-map {
  position: relative;

  &__list {
    background-color: #fff;
  }

  &__item-content {
    overflow: hidden;
    height: 0;
    transition: height .5s;
  }

  &__items {
    overflow-y: scroll;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background-color: var(--grey-stroke-color);
      box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
  }
}

@include respond-up('s-large') {
  .offices-map {
    &__tab{
      padding: 30px;
    }

    &__tab-text{
      font-size: 30px;
      font-weight: bold;
    }

    &__list {
      width: 380px;
    }

    &__items {
      max-height: 543px;
    }
  }
}

@include respond-up('medium') {
  .offices-map {
    &__map-element {
      height: 700px;
    }

    &__inner {
      position: absolute;
      z-index: 50;
      top: 50px;
      left: 0;
      width: 100%;
      height: max-content;
      pointer-events: none;
    }

    &__list {
      pointer-events: auto;
    }
  }
}

@include respond('medium') {
  .offices-map {
    &__list {
      width: 340px;
    }

    &__items {
      max-height: 504px;
    }
  }
}

@include respond-down('medium') {
  .offices-map {
    &__tab{
      padding: 20px;
    }

    &__tab-text{
      font-size: 25px;
      font-weight: bold;
    }
  }
}

@include respond-down('small') {
  .offices-map {
    &__map-element {
      height: 420px;
    }
  }
}