.drop-menu{
  padding: 40px 20px;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.2);
  &__top{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__item-link{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    margin-bottom: 10px;
  }

  &__delimeter{
    align-content: center;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      left: calc(50% - 12px);
      top: -5px;
      width: 25px;
      height: 1px;
      background-color: black;
    }
  }

  &__phone{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
  &__recall{
    margin-top: 30px;
  }

  &__recall-link{
    text-transform: uppercase;
    font-weight: bold;
    padding-bottom: 5px;
    border-bottom: 1px solid black;
  }
}